  /* --joy-palette-primary-50: #fff7ed;
  --joy-palette-primary-100: #ffedd5;
  --joy-palette-primary-200: #fed7aa;
  --joy-palette-primary-300: #fdba74;
  --joy-palette-primary-400: #fb923c;
  --joy-palette-primary-500: #f97316;
  --joy-palette-primary-600: #ea580c;
  --joy-palette-primary-700: #c2410c;
  --joy-palette-primary-800: #9a3412;
  --joy-palette-primary-900: #7c2d12;
  --joy-palette-primary-plainColor: var(--joy-palette-primary-300, #97C3F0);
  --joy-palette-primary-plainHoverBg: var(--joy-palette-primary-800, #0A2744);
  --joy-palette-primary-plainActiveBg: var(--joy-palette-primary-700, #12467B);
  --joy-palette-primary-plainDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-primary-outlinedColor: var(--joy-palette-primary-200, #C7DFF7);
  --joy-palette-primary-outlinedBorder: var(--joy-palette-primary-700, #12467B);
  --joy-palette-primary-outlinedHoverBg: var(--joy-palette-primary-800, #0A2744);
  --joy-palette-primary-outlinedActiveBg: var(--joy-palette-primary-700, #12467B);
  --joy-palette-primary-outlinedDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-primary-outlinedDisabledBorder: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-primary-softColor: var(--joy-palette-primary-200, #C7DFF7);
  --joy-palette-primary-softBg: var(--joy-palette-primary-800, #0A2744);
  --joy-palette-primary-softHoverBg: var(--joy-palette-primary-700, #12467B);
  --joy-palette-primary-softActiveColor: var(--joy-palette-primary-100, #E3EFFB);
  --joy-palette-primary-softActiveBg: var(--joy-palette-primary-600, #185EA5);
  --joy-palette-primary-softDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-primary-softDisabledBg: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-primary-solidColor: var(--joy-palette-common-white, #FFF);
  --joy-palette-primary-solidBg: var(--joy-palette-primary-500, #0B6BCB);
  --joy-palette-primary-solidHoverBg: var(--joy-palette-primary-600, #185EA5);
  --joy-palette-primary-solidActiveBg: var(--joy-palette-primary-700, #12467B);
  --joy-palette-primary-solidDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-primary-solidDisabledBg: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-primary-mainChannel: 251 146 60;
  --joy-palette-primary-lightChannel: 254 215 170;
  --joy-palette-primary-darkChannel: 194 65 12;
  --joy-palette-neutral-50: #FBFCFE;
  --joy-palette-neutral-100: #F0F4F8;
  --joy-palette-neutral-200: #DDE7EE;
  --joy-palette-neutral-300: #CDD7E1;
  --joy-palette-neutral-400: #9FA6AD;
  --joy-palette-neutral-500: #636B74;
  --joy-palette-neutral-600: #555E68;
  --joy-palette-neutral-700: #32383E;
  --joy-palette-neutral-800: #171A1C;
  --joy-palette-neutral-900: #0B0D0E;
  --joy-palette-neutral-plainColor: var(--joy-palette-neutral-300, #CDD7E1);
  --joy-palette-neutral-plainHoverBg: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-neutral-plainActiveBg: var(--joy-palette-neutral-700, #32383E);
  --joy-palette-neutral-plainDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-neutral-outlinedColor: var(--joy-palette-neutral-200, #DDE7EE);
  --joy-palette-neutral-outlinedBorder: var(--joy-palette-neutral-700, #32383E);
  --joy-palette-neutral-outlinedHoverBg: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-neutral-outlinedActiveBg: var(--joy-palette-neutral-700, #32383E);
  --joy-palette-neutral-outlinedDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-neutral-outlinedDisabledBorder: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-neutral-softColor: var(--joy-palette-neutral-200, #DDE7EE);
  --joy-palette-neutral-softBg: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-neutral-softHoverBg: var(--joy-palette-neutral-700, #32383E);
  --joy-palette-neutral-softActiveColor: var(--joy-palette-neutral-100, #F0F4F8);
  --joy-palette-neutral-softActiveBg: var(--joy-palette-neutral-600, #555E68);
  --joy-palette-neutral-softDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-neutral-softDisabledBg: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-neutral-solidColor: var(--joy-palette-common-white, #FFF);
  --joy-palette-neutral-solidBg: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-neutral-solidHoverBg: var(--joy-palette-neutral-600, #555E68);
  --joy-palette-neutral-solidActiveBg: var(--joy-palette-neutral-700, #32383E);
  --joy-palette-neutral-solidDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-neutral-solidDisabledBg: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-neutral-plainHoverColor: var(--joy-palette-neutral-300, #CDD7E1);
  --joy-palette-neutral-mainChannel: 159 166 173;
  --joy-palette-neutral-lightChannel: 221 231 238;
  --joy-palette-neutral-darkChannel: 50 56 62;
  --joy-palette-danger-50: #FEF6F6;
  --joy-palette-danger-100: #FCE4E4;
  --joy-palette-danger-200: #F7C5C5;
  --joy-palette-danger-300: #F09898;
  --joy-palette-danger-400: #E47474;
  --joy-palette-danger-500: #C41C1C;
  --joy-palette-danger-600: #A51818;
  --joy-palette-danger-700: #7D1212;
  --joy-palette-danger-800: #430A0A;
  --joy-palette-danger-900: #240505;
  --joy-palette-danger-plainColor: var(--joy-palette-danger-300, #F09898);
  --joy-palette-danger-plainHoverBg: var(--joy-palette-danger-800, #430A0A);
  --joy-palette-danger-plainActiveBg: var(--joy-palette-danger-700, #7D1212);
  --joy-palette-danger-plainDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-danger-outlinedColor: var(--joy-palette-danger-200, #F7C5C5);
  --joy-palette-danger-outlinedBorder: var(--joy-palette-danger-700, #7D1212);
  --joy-palette-danger-outlinedHoverBg: var(--joy-palette-danger-800, #430A0A);
  --joy-palette-danger-outlinedActiveBg: var(--joy-palette-danger-700, #7D1212);
  --joy-palette-danger-outlinedDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-danger-outlinedDisabledBorder: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-danger-softColor: var(--joy-palette-danger-200, #F7C5C5);
  --joy-palette-danger-softBg: var(--joy-palette-danger-800, #430A0A);
  --joy-palette-danger-softHoverBg: var(--joy-palette-danger-700, #7D1212);
  --joy-palette-danger-softActiveColor: var(--joy-palette-danger-100, #FCE4E4);
  --joy-palette-danger-softActiveBg: var(--joy-palette-danger-600, #A51818);
  --joy-palette-danger-softDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-danger-softDisabledBg: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-danger-solidColor: var(--joy-palette-common-white, #FFF);
  --joy-palette-danger-solidBg: var(--joy-palette-danger-500, #C41C1C);
  --joy-palette-danger-solidHoverBg: var(--joy-palette-danger-600, #A51818);
  --joy-palette-danger-solidActiveBg: var(--joy-palette-danger-700, #7D1212);
  --joy-palette-danger-solidDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-danger-solidDisabledBg: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-danger-mainChannel: 228 116 116;
  --joy-palette-danger-lightChannel: 247 197 197;
  --joy-palette-danger-darkChannel: 125 18 18;
  --joy-palette-success-50: #F6FEF6;
  --joy-palette-success-100: #E3FBE3;
  --joy-palette-success-200: #C7F7C7;
  --joy-palette-success-300: #A1E8A1;
  --joy-palette-success-400: #51BC51;
  --joy-palette-success-500: #1F7A1F;
  --joy-palette-success-600: #136C13;
  --joy-palette-success-700: #0A470A;
  --joy-palette-success-800: #042F04;
  --joy-palette-success-900: #021D02;
  --joy-palette-success-plainColor: var(--joy-palette-success-300, #A1E8A1);
  --joy-palette-success-plainHoverBg: var(--joy-palette-success-800, #042F04);
  --joy-palette-success-plainActiveBg: var(--joy-palette-success-700, #0A470A);
  --joy-palette-success-plainDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-success-outlinedColor: var(--joy-palette-success-200, #C7F7C7);
  --joy-palette-success-outlinedBorder: var(--joy-palette-success-700, #0A470A);
  --joy-palette-success-outlinedHoverBg: var(--joy-palette-success-800, #042F04);
  --joy-palette-success-outlinedActiveBg: var(--joy-palette-success-700, #0A470A);
  --joy-palette-success-outlinedDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-success-outlinedDisabledBorder: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-success-softColor: var(--joy-palette-success-200, #C7F7C7);
  --joy-palette-success-softBg: var(--joy-palette-success-800, #042F04);
  --joy-palette-success-softHoverBg: var(--joy-palette-success-700, #0A470A);
  --joy-palette-success-softActiveColor: var(--joy-palette-success-100, #E3FBE3);
  --joy-palette-success-softActiveBg: var(--joy-palette-success-600, #136C13);
  --joy-palette-success-softDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-success-softDisabledBg: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-success-solidColor: var(--joy-palette-common-white, #FFF);
  --joy-palette-success-solidBg: var(--joy-palette-success-500, #1F7A1F);
  --joy-palette-success-solidHoverBg: var(--joy-palette-success-600, #136C13);
  --joy-palette-success-solidActiveBg: var(--joy-palette-success-700, #0A470A);
  --joy-palette-success-solidDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-success-solidDisabledBg: var(--joy-palette-neutral-800, #171A1C);
  --joy-palette-success-mainChannel: 81 188 81;
  --joy-palette-success-lightChannel: 199 247 199;
  --joy-palette-success-darkChannel: 10 71 10;
  --joy-palette-warning-50: #FEFAF6;
  --joy-palette-warning-100: #FDF0E1;
  --joy-palette-warning-200: #FCE1C2;
  --joy-palette-warning-300: #F3C896;
  --joy-palette-warning-400: #EA9A3E;
  --joy-palette-warning-500: #9A5B13;
  --joy-palette-warning-600: #72430D;
  --joy-palette-warning-700: #492B08;
  --joy-palette-warning-800: #2E1B05;
  --joy-palette-warning-900: #1D1002;
  --joy-palette-warning-plainColor: var(--joy-palette-warning-300, #F3C896);
  --joy-palette-warning-plainHoverBg: var(--joy-palette-warning-800, #2E1B05);
  --joy-palette-warning-plainActiveBg: var(--joy-palette-warning-700, #492B08);
  --joy-palette-warning-plainDisabledColor: var(--joy-palette-neutral-500, #636B74);
  --joy-palette-warning-outlinedColor: var(--joy-palette-warning-200, #FCE1C2);
  --joy-palette-warning-outlinedBorder: var(--joy-palette-warning-700, #492B08);
  --joy-palette-warning-outlinedHoverBg: var(--joy-palette-warning-800, #2E1B05);
  --joy-palette-warning-outlinedActiveBg: var(--joy-palette-warning-700, #492B08); */


.noti-wrapper-root .notistack-MuiContent-error {
  border: 1px solid var(--joy-palette-danger-500);
  color: var(--joy-palette-danger-500);
  background-color: var(--joy-palette-background-popup);
}

.noti-wrapper-root .notistack-MuiContent-error .notistack-var-icon {
  color: var(--joy-palette-danger-500);
}

.noti-wrapper-root .notistack-MuiContent-success {
  border: 1px solid var(--joy-palette-success-500);
  color: var(--joy-palette-success-500);
  background-color: var(--joy-palette-background-popup);
}

.noti-wrapper-root .notistack-MuiContent-success .notistack-var-icon {
  color: var(--joy-palette-success-500);
}

.noti-wrapper-root .notistack-MuiContent-warning {
  border: 1px solid var(--joy-palette-warning-500);
  color: var(--joy-palette-warning-500);
  background-color: var(--joy-palette-background-popup);
}

.noti-wrapper-root .notistack-MuiContent-warning .notistack-var-icon {
  color: var(--joy-palette-warning-500);
}

table tr td {
    text-overflow: ellipsis;
    overflow: hidden;
}

.pac-container.pac-logo {
  z-index: 99999 !important;
}

.lineItem small {
  border: 1px solid;
  padding: 1px 4px;
  display: inline-block;
  font-size: 11px;
  margin-left: 10px;
}

.lineItem .fset {
  color: rgb(216, 2, 2);
}
.lineItem .rset {
  color: rgb(52, 96, 255);
}
.lineItem .n {
  color: rgb(0, 172, 144);
}

.MuiDataGrid-toolbarContainer {
  flex-direction: row-reverse;
  margin-bottom: 12px;
}

.MuiDataGrid-toolbarContainer .MuiDataGrid-toolbarQuickFilter {
  width: 280px;
}

.no-css fieldset {
  display: none;
  height: 100%;
}
.no-css input{ 
  height: 100%;
  padding: 16.5px 8px;
}
.MuiDataGrid-editInputCell input{
  padding: 0 9px !important;
}
.no-css { 
  display: flex;
}
.MuiDataGrid-columnHeaders {
  font-weight: 600 !important;
  --unstable_DataGrid-headWeight: bold; 
  --DataGrid-containerBackground: var(--joy-palette-background-level1);
  --DataGrid-pinnedBackground: var(--joy-palette-background-level1);
  color: var(--joy-palette-neutral-700);
}

.MuiDataGrid-main {
  /* Styles for the first-level, first div */
  border-radius: 10px 10px 0 0;
}
tr.sub_item td{
  border-bottom: 1px solid #f3f3f3;
  height: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

tr.is_bom td {
  border-bottom: 0px !important;
}

.boxNumber {
  color: red;
  border: 1px dashed;
  padding: 0 5px;
  margin-left: 4px;
  margin-top: -8px;
  position: absolute;
  font-size: 10px;
}

.MuiPopper-root  {
  z-index: 99999 !important;
}

thead th {
  vertical-align: middle !important;
}

.brT {
  word-wrap: break-word !important;
  white-space: break-spaces !important;
}

.moveInput input {
  font-size: 15px;
  padding: 0;
  border-bottom: 1px dashed;
  text-align: right;
  margin-top: -7px;
}

.moveInput fieldset {
  display: none !important;
}


.sticky-col {
  position: sticky;
  left: 0;
  border-right:1px solid;
  z-index: 100 !important; /* Brings sticky column on top of other columns */
}

.sticky-col-header {
  position: sticky;
  left: 0;
  border-right:1px solid;
  z-index: 110 !important; /* Brings sticky column on top of other columns */
}

tbody tr:nth-child(even) .sticky-col {
  background-color: var(--joy-palette-background-level1);
}

tbody tr:nth-child(odd) .sticky-col {
  background-color: var(--joy-palette-background-surface) !important; 
}

/* test */


.react-pdf__Page canvas {
  width: "fit-content";
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
}

.PDFViewer__fullscreen {
  position: relative;
}
.react-pdf__Document {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 50px;
}
.ctrol {
  position: fixed;
  z-index: 100;
  width: 100%;
  padding: 8px;
  background: #e5f7e9;
  box-shadow: 0px 2px 10px #818181;
  display: flex;
  justify-content: space-between;
  top: 0;
  align-items: center;
}

.react-pdf__Page {
  position: relative;
}

.base-Popper-root  {
  z-index: 99999 !important;
}